body {
  font-size: 16px;
}

.container {
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;
  height: 100vh;
  background-color: #1e293b;
  color: #fff;
}

.loader {
  width: 120px;
  height: 120px;
  background-color: #06b6d4;
  animation: loading infinite 2.5s;
}

.loader-container {
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.loader-container h2 {
  margin-bottom: 3rem;
}

.error {
  background: #ef4444;
  color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
}

.error-title {
  margin-top: 0;
  font-size: 1.125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.error-icon {
  margin-right: 0.25rem;
}

.error-message {
  margin-bottom: 0;
}

.header {
  background-color: #0f172a;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav {
  padding: 0.75rem 0.5rem;
}

.nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
}

.nav li {
  margin: 0 1rem;
}

.nav-btn {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  padding: 0;
  margin: 0;
  transition: 0.3s;
}

.nav-btn:hover {
  cursor: pointer;
  color: #7dd3fc;
}

.nav-btn.active {
  color: #7dd3fc;
}

.main {
  flex: 1;
  padding: 1rem;
  overflow: scroll;
}

.results {
  list-style: none;
  margin: 0;
  padding: 0;
}

.result {
  display: flex;
  align-items: center;
  width: 100%;
  background: #f5f5f5;
  border: none;
  margin-bottom: 0.75rem;
  font-size: 1rem;
  height: 3rem;
  border-radius: 0.25rem;
  transition: 0.3s;
  text-align: left;
  padding: 0 1rem;
}

.result:hover {
  background: #f3e8ff;
  cursor: pointer;
}

.modal-container {
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  display: flex;
}

.modal-content {
  background: #f5f5f5;
  border-radius: 0.5rem;
  color: #333;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 4rem;
  border-bottom: 1px solid #eee;
}

.modal-title {
  margin: 0;
  font-size: 1.125rem;
}

.modal-image {
  width: 100%;
  border-radius: 1rem;
}

.modal-body {
  padding: 1rem;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: scroll;
}

.modal-data dt {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.modal-data dd {
  margin: 0 0 1rem;
}

.btn-reset {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.sr-only {
  margin-left: -9999px;
}

@media screen and (min-width: 540px) {
  .results {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }
}

@media screen and (min-width: 720px) {
  .container {
    flex-direction: column;
  }

  .results {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .modal-body {
    flex-direction: row;
  }

  .modal-image {
    max-width: 400px;
    margin-right: 1.5rem;
  }
}

@keyframes loading {
  0% {
    background-color: #06b6d4;
  }
  50% {
    transform: rotate(360deg);
    background-color: #10b981;
  }
  100% {
    transform: rotate(720deg);
    background-color: #06b6d4;
  }
}
